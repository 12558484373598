import Vue from 'vue';
import VueRouter from 'vue-router';
// import Home from '../views/Home.vue';

// import AuthRouter from '@/modules/auth/router';
import DashboardRouter from '@/modules/dashboard/router';
import LandingRouter from '@/modules/landing/router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    ...LandingRouter
  },
  {
    path: '/nosotros',
    name: 'nosotros',
    meta: {
        requiresAuth: false,
    },
    component: () =>
        import ( /* webpackChunkName: "nosotros"*/ '@/modules/landing/views/Nosotros.vue')
  },
  {
    path: '/procedimientos',
    name: 'procedimientos',
    meta: {
        requiresAuth: false,
    },
    component: () =>
        import ( /* webpackChunkName: "procedimientos"*/ '@/modules/landing/views/Procedimientos.vue')
  },
  {
    path: '/solicitud',
    name: 'solicitud',
    meta: {
        requiresAuth: false,
    },
    component: () =>
        import ( /* webpackChunkName: "solicitud"*/ '@/modules/landing/views/Solicitud.vue')
  },
  {
    path: '/faq',
    name: 'faq',
    meta: {
        requiresAuth: false,
    },
    component: () =>
        import ( /* webpackChunkName: "faq"*/ '@/modules/landing/views/FAQ.vue')
  },
  {
    path: '/medicos',
    name: 'medicos',
    meta: {
        requiresAuth: false,
    },
    component: () =>
        import ( /* webpackChunkName: "medicos"*/ '@/modules/landing/views/Medicos.vue')
  },

  {
    path: '/dashboard',
    ...DashboardRouter
  }
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: Home,
  // },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  // },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash };
    if (savedPosition) return savedPosition;

    return { x: 0, y: 0 };
  },
  routes,
});

export default router;
