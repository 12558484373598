import axios from "axios";

const state = () => ({
    itemsHome: [],
    itemsAboutUs: [],
    itemsTreatments: [],
    itemsCreditRequests: [],
    itemsFAQ: [],
    itemsDoctors: [],

    itemsMenu: [],
    drawer: true,  
});

const getters = {

};

const mutations = {
    SET_ItemsMenu(state, payload) {
        state.itemsMenu = payload;
    },
    SET_ItemsHome(state, payload) {
        state.itemsHome = payload;
    },
    SET_ItemsAboutUs(state, payload){
        state.itemsAboutUs = payload;
    },
    SET_ItemsTreatments(state, payload){
        state.itemsTreatments = payload;
    },
    SET_ItemsCreditRequests(state, payload){
        state.itemsCreditRequests = payload;
    },
    SET_ItemsFAQ(state, payload) {
        state.itemsFAQ = payload;
    },
    SET_ItemsDoctors(state, payload){
        state.itemsDoctors = payload;
    }
};

const actions = {
    getOpcionesMenu({ commit }) {
        commit("SET_ItemsMenu", [
            {
                id: "1",
                title: "Nosotros",
                to: "/nosotros"
            },
            {
                id: "2",
                title: "Procedimientos",
                to: "/procedimientos"
            },
            {
                id: "3",
                title: "Solicitud de Crédito",
                to: "/solicitud"
            },
            {
                id: "4",
                title: "Preguntas Frecuentes",
                to: "/faq"
            },
            {
                id: "5",
                title: "Médicos",
                to: "/medicos"
            }
        ]);
        // return axios
        // .get(`index.php`)
        // .then((response) => {
        //     // console.log("getOpcionesMenu: ", response);
        //     if (response.data) {
        //     commit("SET_ItemsMenu", response.data);
        //     }
        // })
        // .catch(function (err) {
        //     console.log("err:", err);
        // });
    },
    async getItemsHome({ commit }){
        commit("SET_ItemsHome", []);

        return axios
            .get(`home.php`)
            .then((response) => {
                // console.log("getItemsHome: ", response);
                if (response.data) {
                    commit("SET_ItemsHome", response.data);
                }
            })
            .catch(function (err) {
                console.log("err:", err);
            });
    },
    async getItemsAboutUs({ commit }){
        return axios
            .get(`about_us.php`)
            .then((response) => {
                if (response.data) {
                    console.log("getItemsAboutUs: ", response.data);
                    commit("SET_ItemsAboutUs", response.data);
                }
            })
            .catch(function (err) {
                console.log("err:", err);
            });
    },
    async getItemsTreatments({ commit }){
        return axios
            .get(`treatments.php`)
            .then((response) => {
                if (response.data) {
                    console.log("getItemsTreatments: ", response.data);
                    commit("SET_ItemsTreatments", response.data);
                }
            })
            .catch(function (err) {
                console.log("err:", err);
            });
    },
    async getItemsCreditRequests({ commit }){
        return axios
            .get(`credit_request.php`)
            .then((response) => {
                if (response.data) {
                    console.log("getItemsCreditRequests: ", response.data);
                    commit("SET_ItemsCreditRequests", response.data);
                }
            })
            .catch(function (err) {
                console.log("err:", err);
            });
    },
    async getItemsFAQ({ commit }) {
        return axios
            .get(`faq.php`)
            .then((response) => {
                if (response.data) {
                    console.log("getItemsFAQ: ", response.data);
                    commit("SET_ItemsFAQ", response.data);
                }
            })
            .catch(function (err) {
                console.log("err:", err);
            });
    },
    async getItemsDoctors({ commit }){
        return axios
            .get(`doctors.php`)
            .then((response) => {
                if (response.data) {
                    console.log("getItemsDoctors: ", response.data);
                    commit("SET_ItemsDoctors", response.data);
                }
            })
            .catch(function (err) {
                console.log("err:", err);
            });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  };