import vue from "vue";
import axios from "axios";

axios.interceptors.request.use(request => {
  request.config = {
    showToast: false,
    ...(request.config ?? {}),
    start: Date.now()
  };

  if (request.config.showToast) {
    request.config.requestToastId = vue.$toast(
      request.config.requestToast.title
    );
  }

  return request;
});

axios.interceptors.response.use(
  response => {
    // const now = Date.now();
    const request = response.config;
    // console.info(
    //   `API Call ${request.url} took ${now - request.config.start}ms`
    // );
    // console.log("Interceptiors: ", response);
    if (request.requestToastId) {
      vue.$toast.dismiss(request.requestToastId);
    }

    if (request.showToast && request.responseToast) {
      vue.$toast(request.responseToast.title);
    }

    return response;
  },
  error => Promise.reject(error)
);

axios.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  const resp = response.config;
  // console.info(
  //   `API Call ${request.url} took ${now - request.config.start}ms`
  // );
  // console.log("Interceptiors: ", response);
  // console.log("Interceptiors-resp: ", resp);
  if (resp.requestToastId) {
    vue.$toast.dismiss(resp.requestToastId);
  }

  if (resp.showToast && resp.responseToast) {
    vue.$toast(resp.responseToast.title);
  }

  return response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  return Promise.reject(error);
});

export default axios;
