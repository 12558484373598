const state = () => ({
  loading: false,
  requestsPending: 0
});

const mutations = {
  show(state) {
    state.loading = true;
  },
  hide(state) {
    state.loading = false;
  },
  pending(state) {
    if (state.requestsPending === 0) {
      this.commit("loader/show");
    }

    state.pending++;
  },
  done(state) {
    if (state.requestsPending >= 1) {
      state.pending--;
    }

    if (state.requestsPending <= 0) {
      this.commit("loader/hide");
    }
  }
};

const actions = {
  show({ commit }) {
    commit("show");
  },
  hide({ commit }) {
    commit("hide");
  },
  pending({ commit }) {
    commit("pending");
  },
  done({ commit }) {
    commit("done");
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
