import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#243144",
        secondary: "#577299",
        accent: "#bcd5ea",
        dark: "#495867"
      }
    }
  }
});
