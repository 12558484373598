import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Vuelidate from "vuelidate";
import axios from "./plugins/axios";

import toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import VueYouTubeEmbed from 'vue-youtube-embed'
import VueMeta from "vue-meta";
import * as VueGoogleMaps from "vue2-google-maps";
import { LMap, LTileLayer, LMarker } from "vue2-leaflet";
import "leaflet/dist/leaflet.css";
import AOS from 'aos';
import 'aos/dist/aos.css';

import VueSweetalert2 from 'vue-sweetalert2';
// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';

import { API_BASE_URLS } from './utils/config';

Vue.config.productionTip = false

Vue.use(VueYouTubeEmbed)

Vue.use(toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 5,
  newestOnTop: true
});

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyDLFt6s5TZ92j2cF_oXiBG5S2nr3PAigCA",
    libraries: "places"
  }
});

Vue.component("l-map", LMap);
Vue.component("l-tile-layer", LTileLayer);
Vue.component("l-marker", LMarker);

Vue.component("google-map", VueGoogleMaps.Map);
Vue.use(Vuelidate);
Vue.use(VueMeta);

Vue.use(AOS.init({
  // Opciones de configuración de AOS, si es necesario
}));

Vue.use(VueSweetalert2);

axios.defaults.showLoader = true;

axios.defaults.baseURL = API_BASE_URLS.default;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  created() {
    axios.interceptors.request.use(function (config) {
      if (config.showLoader) {
        store.dispatch("loader/pending");
      }
      return config;
    }, function (error) {
      if (error.config.showLoader) {
        store.dispatch("loader/done");
      }
      return Promise.reject(error);
    });

    axios.interceptors.response.use(function (response) {
      if (response.config.showLoader) 
        store.dispatch("loader/done");
      
      return response;
    }, function (error) {
      // console.log("axios.interceptors.response: ", error);
      if (error.config.showLoader) {
        store.dispatch("loader/done");
      }
      return Promise.reject(error);
    });
  }
}).$mount('#app')

// async function refreshToken() {
//   try {
//     const response = await axios.post("/Account/RenovarToken", {
//       refreshToken: localStorage.getItem("refreshToken")
//     });
//     return response.data.token;
//   } catch (error) {
//     console.error("Error al obtener un nuevo token:", error);
//     return null;
//   }
// }
