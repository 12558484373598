import decode from "jwt-decode";

const state = () => ({
  token: null,
  expiration: null,
  user: null,
  loginState: ""
});

const getters = {
  getLoginStatus(state) {
    return state.user;
  }
};

const mutations = {
  SET_Token(state, payload) {
    state.token = payload;
  },
  SET_Usuario(state, payload) {
    state.user = payload;
  },
  SET_Expiration(state, payload) {
    state.expiration = payload;
  },
  SET_LoginStatus(state, payload) {
    state.loginState = payload;
  }
};

const actions = {
  guardarToken({ commit }, usertoken) {
    commit("SET_Token", usertoken.token);
    commit("SET_Usuario", decode(usertoken.token));
    commit("SET_Expiration", usertoken.expiration);
    localStorage.setItem("Bearer", usertoken.token);
  },
  autoLogin({ commit }) {
    let token = localStorage.getItem("Bearer");
    if (token) {
      commit("SET_Token", token);
      commit("SET_Usuario", decode(token));
    }
  
  },
  logout({ commit }) {
    commit("SET_Token", null);
    commit("SET_Usuario", null);
    localStorage.removeItem("Bearer");
  
  },
  obtInfoToken({ commit }) {
    let token = localStorage.getItem("Bearer");
    if (token) {
      commit("SET_Token", token);
      commit("SET_Usuario", decode(token));
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
