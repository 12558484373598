<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import "@/assets/css/style.css";

export default {
  name: 'App',
  metaInfo: {
    title: "MEDI ASSIST",
    // titleTemplate: '%s | Portal',
    author: "Alfirian"
  },
  components: {
    
  },
  data: () => ({
    //
  }),
};
</script>
