import Vue from "vue";
import Vuex from "vuex";

// import { state } from "./state";
// import { mutations } from "./mutations";
// import { actions } from "./actions";
import auth from "./modules/auth";
import loader from "./modules/loader";
import landing from "./modules/landing";

Vue.use(Vuex);

const store = new Vuex.Store({
  // state,
  // getters: {
  //   catCategorias: state => {
  //     return state.catCategorias;
  //   }
  // },
  // mutations,
  // actions,
  modules: {
    auth,
    loader,
    landing
  }
});

export default store;
